import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as bannersActions from '@redux/modules/banners';
import BannerSlider from '@routes/index/components/BannerSliderSection';
import { useActions } from '@utils/hooks';

const BannerSliderSectionContainer = () => {
  const { locale } = useRouter();
  const { authUser, banners, loadingAuthUser } = useAppSelector((state) => ({
    authUser: state.authUser,
    banners: state.banners,
    loadingAuthUser: state.loadingAuthUser,
  }));
  const { getBanners } = useActions({
    ...bannersActions,
  });

  useEffect(() => {
    if (loadingAuthUser) {
      return;
    }
    getBanners(locale, authUser);
  }, [authUser, loadingAuthUser, locale]);

  if (!banners?.length) {
    return null;
  }

  return <BannerSlider banners={banners} />;
};

export default BannerSliderSectionContainer;
