import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import LPSection from '@components/LPSection';
import BenefitSectionContainer from '@features/lp/containers/BenefitSectionContainer';
import FaqSectionContainer from '@features/lp/containers/FaqSectionContainer';
import IntroductionSectionContainer from '@features/lp/containers/IntroductionSectionContainer';
import PlanCardsSectionContainer from '@features/plan-cards/containers/PlanCardsSectionContainer';
import PropertyLPSectionContainer from '@features/properties/containers/PropertyLPSectionContainer';
import TopicSection from '@features/topics/components/TopicSection';
import TipsOfUsingHafhForJapanContainer from '@features/topics/containers/TipsOfUsingHafhForJapanContainer';
import TipsOfUsingHafhForTaiwanContainer from '@features/topics/containers/TipsOfUsingHafhForTaiwanContainer';
import TravelStoryLPSectionContainer from '@features/travel-stories/containers/TravelStoryLPSectionContainer';
import KeyVisualSection from '@routes/index/components/KeyVisualSection';
import CampaignBannerContainer from '@routes/index/container/CampaignBannerContainer';
import FloatCtaButtonContainer from '@routes/index/container/FloatCtaButtonContainer';
import { LANG_LOCALE } from '@utils/types';
import styles from './css';

type Props = {
  isJapanUser: boolean;
  isTaiwanUser: boolean;
  locale: LANG_LOCALE;
};

const IndexView: FC<Props> = ({ isJapanUser, isTaiwanUser, locale }) => {
  const { t } = useTranslation('top');

  return (
    <div className="index-view">
      <KeyVisualSection />
      <CampaignBannerContainer />
      <LPSection title={t('introductionSection.title')}>
        <IntroductionSectionContainer />
      </LPSection>
      {/* Japan region displays the benefit section, and if there is a benefit section, displays a triangle. */}
      <LPSection
        hasBorder={!isJapanUser}
        hasTriangle={isJapanUser}
        title={t('planSection.title')}
      >
        <PlanCardsSectionContainer />
      </LPSection>
      {isJapanUser && <BenefitSectionContainer />}
      <LPSection
        hasPaddingHorizontal={false}
        subTitle={t('propertySection.subTitle')}
        title={t('propertySection.title')}
      >
        <PropertyLPSectionContainer />
      </LPSection>
      <LPSection
        hasPaddingHorizontal={false}
        subTitle={t('travelStorySection.subTitle')}
        title={t('travelStorySection.title')}
      >
        <TravelStoryLPSectionContainer />
      </LPSection>
      {isJapanUser && locale === 'ja' && (
        <LPSection
          hasPaddingHorizontal={false}
          subTitle="HafHで実現する、それぞれの旅のかたち"
          title="みんなのHafH体験記"
        >
          <TipsOfUsingHafhForJapanContainer />
        </LPSection>
      )}
      {isTaiwanUser && locale === 'zh-tw' && (
        <LPSection
          hasPaddingHorizontal={false}
          subTitle="因為有 HafH，讓我們的旅行變得更輕鬆、更加自由"
          title="HafH 使用情報"
        >
          <TipsOfUsingHafhForTaiwanContainer />
        </LPSection>
      )}
      <LPSection title={t('faqSection.title')}>
        <FaqSectionContainer />
      </LPSection>
      <TopicSection />
      <FloatCtaButtonContainer />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default IndexView;
