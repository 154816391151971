import { useEffect } from 'react';
import * as topicCategoriesActions from '@redux/modules/topicCategories';
import { useActions } from '@utils/hooks';

type Props = {
  slugs: string[];
};

export const useGetTopicCategories = ({ slugs }: Props) => {
  const { getTopicCategories } = useActions({
    ...topicCategoriesActions,
  });

  useEffect(() => {
    getTopicCategories(slugs);
  }, [slugs.length]);
};
