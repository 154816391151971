import { useRouter } from 'next/router';
import IndexView from '@routes/index/view/IndexView';
import { useIsJapanUser, useIsTaiwanUser } from '@utils/hooks';
import { LANG_LOCALE } from '@utils/types';

const IndexViewContainer = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const isJapanUser = useIsJapanUser();
  const isTaiwanUser = useIsTaiwanUser();

  return (
    <IndexView
      isJapanUser={isJapanUser}
      isTaiwanUser={isTaiwanUser}
      locale={locale}
    />
  );
};

export default IndexViewContainer;
