import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import UpcomingPropertyReservations from '@features/properties/components/UpcomingPropertyReservations';
import { useGetUpcomingReservations } from '@features/properties/hooks/useGetUpcomingReservations';
import { filterUpcomingReservations } from '@features/properties/utils';
import { useAppSelector } from '@hooks/useAppSelector';
import { LANG_LOCALE } from '@utils/types';

const UpcomingPropertyReservationsContainer = () => {
  const { t } = useTranslation('browse');
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { reservations } = useAppSelector((state) => ({
    reservations: state.reservations,
  }));
  const upcomingReservations = filterUpcomingReservations(
    reservations?.upcoming?.reservations
  );

  useGetUpcomingReservations();

  if (!upcomingReservations?.length) {
    return null;
  }

  return (
    <UpcomingPropertyReservations
      locale={locale}
      reservations={upcomingReservations}
      t={t}
    />
  );
};

export default UpcomingPropertyReservationsContainer;
