import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .campaign-banner-section {
    padding: 24px 16px;
  }
  .campaign-banner {
    position: relative;
    max-width: 912px;
    margin: 0 auto;
  }
  .campaign-banner-inner {
    position: relative;
    aspect-ratio: 343/220;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      aspect-ratio: 912/160;
    }
  }
`;

export default styles;
