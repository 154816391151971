import css from 'styled-jsx/css';
import { HERO_HEIGHT } from '@routes/index/utils/constants';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .hero-images-wrapper {
    position: relative;
    .catch-copy {
      position: absolute;
      top: 100px;
      z-index: 2;
      width: 100%;
      text-align: center;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        top: 140px;
      }
      :global(.typo-xl) {
        @media screen and (min-width: ${BREAKPOINT.mobile}px) {
          white-space: nowrap;
        }
      }
    }
    .hero-images-information {
      position: relative;
      height: ${HERO_HEIGHT}px;
    }
    .description-cont {
      margin: auto;
      max-width: 1232px;
    }
    .hero-images-subTitle {
      margin-top: 2px;
      margin-bottom: 6px;
    }
    .hero-images-subItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .description {
      bottom: 44px;
      left: 0;
      padding-left: 16px;
      padding-right: 16px;
      position: absolute;
      width: 100%;
      z-index: 10;
      @media screen and (min-width: ${BREAKPOINT.mobile}px) {
        padding-left: 24px;
        padding-right: 24px;
      }
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        bottom: 38px;
      }
      @media screen and (min-width: ${BREAKPOINT.desktopL}px) {
        padding-left: 40px;
        padding-right: 40px;
      }

      :global(.hero-images-link) {
        color: ${COLORS.white};
        text-decoration: underline;
      }
      .property-details {
        flex: 1;
      }
      .property-details-coin {
        :global(.icon-coin) {
          margin-right: 2px;
          margin-bottom: 3px;
        }
      }
    }
    :global(.swiper-pagination-bullet) {
      background-color: rgba(255, 255, 255, 0.4);
      opacity: initial;
    }
    :global(.swiper-pagination-bullet-active) {
      background-color: ${COLORS.white};
    }
    :global(.swiper-slide) {
      height: ${HERO_HEIGHT}px;
      &:before {
        position: absolute;
        z-index: 1;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0.1) 51%,
          rgba(0, 0, 0, 0.4) 100%
        );
      }
    }
    :global(.swiper-pagination) {
      bottom: 24px;
    }
  }
`;

export default styles;
