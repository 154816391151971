import { useGetTopicCategories } from '@features/topics/hooks/useGetTopicCategories';
import { useNeighbor } from '@hooks/useNeighbor';
import AuthedIndexViewContainer from '@routes/index/container/AuthedIndexViewContainer';
import IndexViewContainer from '@routes/index/container/IndexViewContainer';

const IndexContainer = () => {
  const { isLoggedIn } = useNeighbor();

  useGetTopicCategories({
    slugs: ['property-release', 'experience'],
  });

  if (isLoggedIn) {
    return <AuthedIndexViewContainer />;
  }

  return <IndexViewContainer />;
};

export default IndexContainer;
