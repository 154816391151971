import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .index-view {
    padding-bottom: 48px;
    background-color: ${COLORS.black100};
  }
`;

export default styles;
