import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as reservationActions from '@redux/modules/reservations';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';

export const useGetUpcomingReservations = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { authUser, loadingAuthUser } = useAppSelector((state) => ({
    authUser: state.authUser,
    loadingAuthUser: state.loadingAuthUser,
  }));
  const { getUpcomingReservations } = useActions({
    ...reservationActions,
  });

  useEffect(() => {
    if (!loadingAuthUser && !isEmpty(authUser)) {
      getUpcomingReservations(authUser, locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAuthUser]);
};
