import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .wrapper {
    background-color: ${COLORS.navy};
  }
`;

export default styles;
