import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .reservation-item {
    display: flex;
    background: ${COLORS.white};
    border-radius: 8px;
    box-shadow: 0 4px 4px -2px rgba(24, 39, 75, 0.08);
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 8px 8px -2px rgba(24, 39, 75, 0.12);
    }
    :global(img) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .property-box {
      margin: 12px;
      :global(h1) {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: ${COLORS.black900};
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 4px;
      }
    }
  }
`;

export default styles;
