import TipsOfUsingHafhSection from '@features/topics/components/TipsOfUsingHafhSection';
import { TIPS_OF_USING_HAFH_FOR_TAIWAN } from '@features/topics/constants';

const TipsOfUsingHafhForTaiwanContainer = () => {
  return (
    <TipsOfUsingHafhSection
      contents={TIPS_OF_USING_HAFH_FOR_TAIWAN}
      lang="zh-tw"
    />
  );
};

export default TipsOfUsingHafhForTaiwanContainer;
