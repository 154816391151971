import { useRouter } from 'next/router';
import { useGetFavoriteProperties } from '@features/favorite-properties/hooks/useGetFavoriteProperties';
import { useGetAvailableFlights } from '@features/flights/hooks/useGetAvailableFlights';
import { useDisplayFailedPaymentStatus } from '@features/payment/hooks/useDisplayFailedPaymentStatus';
import { useGetUpcomingReservations } from '@features/properties/hooks/useGetUpcomingReservations';
import { filterUpcomingReservations } from '@features/properties/utils';
import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import AuthedIndexView from '@routes/index/view/AuthedIndexView';
import type { Neighbor } from '@services/hafh/types/generated';
import {
  useDisplayEmailVerificationToast,
  useIsJapanUser,
  useIsTaiwanUser,
} from '@utils/hooks';
import { getNeighborSubscribed } from '@utils/neighbor';
import routes from '@utils/routes';
import { LANG_LOCALE } from '@utils/types';

const AuthedIndexViewContainer = () => {
  const { locale, push } = useRouter();
  const { neighbor } = useNeighbor();
  const isSubscribed = getNeighborSubscribed(neighbor as Neighbor);
  const isJapanUser = useIsJapanUser();
  const isTaiwanUser = useIsTaiwanUser();
  const { availableFlights, gachaCabinets, reservations } = useAppSelector(
    (state) => ({
      availableFlights: state.availableFlights,
      gachaCabinets: state.gachaCabinet.gachaCabinets,
      reservations: state.reservations,
    })
  );
  const upcomingReservations = filterUpcomingReservations(
    reservations?.upcoming?.reservations
  );
  const hasUpcomingReservations = !!upcomingReservations?.length;
  const hasAvailableFlights = !!availableFlights?.length;

  useDisplayEmailVerificationToast();
  useGetFavoriteProperties();
  useDisplayFailedPaymentStatus();
  useGetUpcomingReservations();
  useGetAvailableFlights();

  const handleClickCampaignDialogPrimaryButton = () => {
    push(routes.gachas);
  };

  return (
    <AuthedIndexView
      gachaCabinets={gachaCabinets}
      handleClickCampaignDialogPrimaryButton={
        handleClickCampaignDialogPrimaryButton
      }
      hasAvailableFlights={hasAvailableFlights}
      hasUpcomingReservations={hasUpcomingReservations}
      isJapanUser={isJapanUser}
      isSubscribed={isSubscribed}
      isTaiwanUser={isTaiwanUser}
      locale={locale as LANG_LOCALE}
    />
  );
};

export default AuthedIndexViewContainer;
