import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Head from '@components/Head';
import Page from '@components/Page';
import { useAppSelector } from '@hooks/useAppSelector';
import * as neighborPlanActions from '@redux/modules/neighborPlans';
import IndexContainer from '@routes/index/container';
import { getIsDuringGacha20250306Campaign } from '@utils/campaign';
import { PUBLIC_IMAGES_URL } from '@utils/constants';
import { useActions } from '@utils/hooks';
import { isEmpty } from '@utils/utils';

const IndexPage = () => {
  const { lang, t } = useTranslation('common');
  const { authUser } = useAppSelector((state) => ({
    authUser: state.authUser,
  }));
  const { getNeighborPlans } = useActions({
    ...neighborPlanActions,
  });

  useEffect(() => {
    getNeighborPlans(lang, isEmpty(authUser) ? null : authUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const isDuringGachaCampaign = getIsDuringGacha20250306Campaign();

  return (
    <Page isHeaderLogoH1={true}>
      <Head
        image={
          lang === 'ja'
            ? isDuringGachaCampaign
              ? `${PUBLIC_IMAGES_URL}/images/campaign/gacha-202503/ogp.png`
              : `${PUBLIC_IMAGES_URL}/images/jp-top/ogp.png`
            : ''
        }
        title={t('meta.title')}
        type="website"
      />
      <IndexContainer />
    </Page>
  );
};

export default IndexPage;
