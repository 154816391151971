import AutoSuggestInHeader from '@molecules/AutoSuggestInHeader';
import BannerSliderSectionContainer from '@routes/index/container/BannerSliderSectionContainer';
import Hero from '../Hero';
import styles from './css';

const KeyVisualSection = () => (
  <div>
    <Hero />
    <div className="key-visual-auto-suggest">
      <AutoSuggestInHeader />
    </div>
    <BannerSliderSectionContainer />
    <style jsx={true}>{styles}</style>
  </div>
);

export default KeyVisualSection;
