import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as favoritePropertiesActions from '@redux/modules/favoriteProperties';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';

export const useGetFavoriteProperties = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { authUser, loadingAuthUser, neighbor } = useAppSelector((state) => ({
    authUser: state.authUser,
    loadingAuthUser: state.loadingAuthUser,
    neighbor: state.neighbor,
  }));
  const { getFavoriteProperties } = useActions({
    ...favoritePropertiesActions,
  });

  useEffect(() => {
    if (loadingAuthUser) {
      return;
    }

    if (!isEmpty(authUser) || !isEmpty(neighbor)) {
      getFavoriteProperties(authUser, locale);
    }
  }, [authUser, loadingAuthUser, locale, neighbor]);
};
