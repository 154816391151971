import { Reservation } from '@services/hafh/types/generated';
import utilsReservation from '@utils/reservation';
import utils from '@utils/utils';

const MAX_UPCOMING_RESERVATIONS = 3;

export const filterUpcomingReservations = (
  reservations: Reservation[] | undefined
) => {
  if (!reservations) {
    return [];
  }

  return reservations.filter(
    (r, index) =>
      utilsReservation.isReservationConfirmed(r) &&
      utils.isSameOrBefore(r.check_in_date, 'day') &&
      index <= MAX_UPCOMING_RESERVATIONS
  );
};
