import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .top-reservation-wrapper {
    :global(.section-header) {
      padding: 0 16px 16px;
    }
  }
  .top-reservation-list {
    display: flex;
    gap: 16px;
    padding: 0 16px;
    > li {
      flex: 1;
      max-width: 33.333%;
      @media (max-width: ${BREAKPOINT.desktopL}px) {
        max-width: 50%;
        :nth-of-type(n + 3) {
          display: none;
        }
      }
      @media (max-width: ${BREAKPOINT.desktop}px) {
        max-width: 100%;
        :nth-of-type(n + 2) {
          display: none;
        }
      }
    }
  }
`;

export default styles;
