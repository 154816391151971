import * as heroImagesActions from '@redux/modules/heroImages';
import * as signupLimitActions from '@redux/modules/signupLimit';
import * as usedCoinsRankingActions from '@redux/modules/usedCoinsRanking';
import reduxWrapper from '@redux/store';
import IndexPage from '@routes/index';

const Index = () => <IndexPage />;

export const getServerSideProps = reduxWrapper.getServerSideProps(
  (_store) =>
    async ({ locale }) => {
      await Promise.all([
        _store.dispatch(signupLimitActions.getSignupLimit(locale)),
        _store.dispatch(usedCoinsRankingActions.getUsedCoinsRanking(locale)),
        _store.dispatch(heroImagesActions.getHeroImages(locale)),
      ]);
    }
);

export default Index;
