import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import CampaignDialog from '@components/CampaignDialog';
import LPSection from '@components/LPSection';
import FlightSliderContainer from '@features/flights/containers/FlightSliderContainer';
import { CAMPAIGN_DIALOG_TITLE } from '@features/gachas/constants';
import BenefitSectionContainer from '@features/lp/containers/BenefitSectionContainer';
import IntroductionSectionContainer from '@features/lp/containers/IntroductionSectionContainer';
import PlanCardsSectionContainer from '@features/plan-cards/containers/PlanCardsSectionContainer';
import PropertyLPSectionContainer from '@features/properties/containers/PropertyLPSectionContainer';
import UpcomingPropertyReservationsContainer from '@features/properties/containers/UpcomingPropertyReservationsContainer';
import TopicSection from '@features/topics/components/TopicSection';
import TipsOfUsingHafhForJapanContainer from '@features/topics/containers/TipsOfUsingHafhForJapanContainer';
import TipsOfUsingHafhForTaiwanContainer from '@features/topics/containers/TipsOfUsingHafhForTaiwanContainer';
import TravelStoryLPSectionContainer from '@features/travel-stories/containers/TravelStoryLPSectionContainer';
import EKycAlert from '@molecules/EKycAlert';
import SignUpCoinPackDialog from '@molecules/SignUpCoinPackDialog';
import type { GachaCabinets } from '@redux/modules/gachaCabinet';
import KeyVisualSection from '@routes/index/components/KeyVisualSection';
import CampaignBannerContainer from '@routes/index/container/CampaignBannerContainer';
import { LANG_LOCALE } from '@utils/types';
import styles from './css';

type Props = {
  gachaCabinets: GachaCabinets;
  handleClickCampaignDialogPrimaryButton: () => void;
  hasAvailableFlights: boolean;
  hasUpcomingReservations: boolean;
  isJapanUser: boolean;
  isSubscribed: boolean;
  isTaiwanUser: boolean;
  locale: LANG_LOCALE;
};

const AuthedIndexView: FC<Props> = ({
  gachaCabinets,
  handleClickCampaignDialogPrimaryButton,
  hasAvailableFlights,
  hasUpcomingReservations,
  isJapanUser,
  isSubscribed,
  isTaiwanUser,
  locale,
}) => {
  const { t } = useTranslation('top');

  return (
    <div className="index-view">
      <EKycAlert />
      <KeyVisualSection />
      <CampaignBannerContainer />
      {hasUpcomingReservations && (
        <LPSection
          hasPaddingHorizontal={false}
          shouldDisplayHeaderSection={false}
          subTitle={t('propertySection.subTitle')}
          title={t('propertySection.title')}
        >
          <UpcomingPropertyReservationsContainer />
        </LPSection>
      )}
      {!isSubscribed && (
        <>
          <LPSection title={t('introductionSection.title')}>
            <IntroductionSectionContainer />
          </LPSection>
          {/* Japan region displays the benefit section, and if there is a benefit section, displays a triangle. */}
          <LPSection
            hasBorder={!isJapanUser}
            hasTriangle={isJapanUser}
            title={t('planSection.title')}
          >
            <PlanCardsSectionContainer />
          </LPSection>
          {isJapanUser && <BenefitSectionContainer />}
        </>
      )}
      <LPSection
        hasPaddingHorizontal={false}
        shouldDisplayHeaderSection={!isSubscribed}
        subTitle={t('propertySection.subTitle')}
        title={t('propertySection.title')}
      >
        <PropertyLPSectionContainer />
      </LPSection>
      <LPSection
        hasPaddingHorizontal={false}
        shouldDisplayHeaderSection={!isSubscribed}
        subTitle={t('travelStorySection.subTitle')}
        title={t('travelStorySection.title')}
      >
        <TravelStoryLPSectionContainer />
      </LPSection>
      {isJapanUser && hasAvailableFlights && (
        <LPSection
          hasPaddingHorizontal={false}
          shouldDisplayHeaderSection={!isSubscribed}
          subTitle={t('flightSection.subTitle')}
          title={t('flightSection.title')}
        >
          <FlightSliderContainer />
        </LPSection>
      )}
      {!isSubscribed && isJapanUser && locale === 'ja' && (
        <LPSection
          hasPaddingHorizontal={false}
          subTitle="HafHで実現する、それぞれの旅のかたち"
          title="みんなのHafH体験記"
        >
          <TipsOfUsingHafhForJapanContainer />
        </LPSection>
      )}
      {!isSubscribed && isTaiwanUser && locale === 'zh-tw' && (
        <LPSection
          hasPaddingHorizontal={false}
          subTitle="因為有 HafH，讓我們的旅行變得更輕鬆、更加自由"
          title="HafH 使用情報"
        >
          <TipsOfUsingHafhForTaiwanContainer />
        </LPSection>
      )}
      <TopicSection />
      <SignUpCoinPackDialog />
      {gachaCabinets.map((gachaCabinet) => (
        <CampaignDialog
          campaignCode={gachaCabinet?.code || ''}
          headerText={CAMPAIGN_DIALOG_TITLE[gachaCabinet?.code || '']}
          imagePath={`/images/gacha/${gachaCabinet.code}/dialog.png`}
          key={gachaCabinet.id}
          localStorageKey={`showDialog_${gachaCabinet.code}`}
          shouldShowDialog={Boolean(
            gachaCabinet?.status?.active && gachaCabinet?.status?.eligible
          )}
          onPrimaryButtonClick={handleClickCampaignDialogPrimaryButton}
        />
      ))}

      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default AuthedIndexView;
