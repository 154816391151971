import Image from 'next/image';
import Link from 'next/link';
import { Desktop, Mobile } from '@components/Layout';
import styles from './css';

const CampaignBanner = () => {
  return (
    <div className="campaign-banner-section">
      <div className="campaign-banner">
        <div className="campaign-banner-inner">
          <Link href="/topics/22327">
            <Desktop>
              <Image
                fill
                alt="空旅ガチャアップデート記念！招待キャンペーン初月30%OFF"
                src="/images/campaign/gacha-202503/landscape-banner.png?v=2"
              />
            </Desktop>
            <Mobile>
              <Image
                fill
                alt="空旅ガチャアップデート記念！招待キャンペーン初月30%OFF"
                src="/images/campaign/gacha-202503/invite-key-visual.png?v=2"
              />
            </Mobile>
          </Link>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default CampaignBanner;
