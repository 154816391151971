import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .key-visual-auto-suggest {
    position: relative;
    z-index: 2;
    isolation: isolate;
    padding: 12px 16px;
    background-color: ${COLORS.purple900};
    :global(.auto-suggest-container) {
      @media screen and (min-width: ${BREAKPOINT.mobileS}px) {
        width: 448px;
      }
      @media screen and (min-width: ${BREAKPOINT.mobile}px) {
        width: 720px;
      }
      @media screen and (min-width: ${BREAKPOINT.desktopL}px) {
        width: 822px;
      }
    }
  }
`;

export default styles;
