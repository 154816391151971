import { FC } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Image from 'next/image';
import { TypoXSBold } from '@atoms/Typos';
import Link from '@components/Link';
import { Reservation } from '@services/hafh/types/generated';
import routes, { route } from '@utils/routes';
import { LANG_LOCALE } from '@utils/types';
import styles from './css';

type Props = {
  locale: LANG_LOCALE;
  reservation: Reservation;
};

const UpcomingPropertyReservation: FC<Props> = ({ locale, reservation }) => {
  const {
    check_in_date: checkInDate,
    check_out_date: checkOutDate,
    confirmation_number: confirmationNumber,
    property,
  } = reservation;
  let checkIn = checkInDate;
  let checkOut = checkOutDate;

  let checkInFormat;
  let checkOutFormat;

  if (locale === 'ja') {
    checkInFormat = 'YYYY年MMMD日';

    if (moment(checkIn).isSame(checkOut, 'year')) {
      checkOutFormat = 'MMMD日';
    } else {
      checkOutFormat = 'YYYY年MMMD日';
    }
  } else {
    checkOutFormat = 'MMM D, YYYY';

    if (moment(checkIn).isSame(checkOut, 'year')) {
      checkInFormat = 'MMM D';
    } else {
      checkInFormat = 'MMM D, YYYY';
    }
  }

  moment.locale(locale);
  checkIn = moment(checkIn).format(checkInFormat);
  checkOut = moment(checkOut).format(checkOutFormat);

  return (
    <Link
      href={route(routes.reservation, {
        confirmationNumber,
      })}
    >
      <div className="reservation-item">
        <div className={classNames('property-image')}>
          <Image
            alt={property.name}
            height={88}
            src={property.thumbnail_url}
            width={88}
          />
        </div>
        <div className="property-box">
          <div className="property-detail-cont">
            <span className="property-title">
              <h1>{property.name}</h1>
              <TypoXSBold color="black600">
                <time>{`${checkIn} ~ ${checkOut}`}</time>
              </TypoXSBold>
            </span>
          </div>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Link>
  );
};

export default UpcomingPropertyReservation;
