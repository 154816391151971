import { FC } from 'react';
import { Desktop, Mobile } from '@components/Layout';
import BannerSlider from '../BannerSlider';
import styles from './css';

type Props = {
  banners: {
    image_url: string;
    link: string;
  }[];
};

const BannerSliderSection: FC<Props> = ({ banners }) => (
  <div className="wrapper">
    <Desktop>
      <BannerSlider banners={[...banners]} />
    </Desktop>
    <Mobile>
      <BannerSlider banners={[...banners]} isMobile={true} />
    </Mobile>
    <style jsx={true}>{styles}</style>
  </div>
);

export default BannerSliderSection;
