import { CSSProperties } from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper as SwiperLibrary, SwiperSlide } from 'swiper/react';
import {
  TypoSBold,
  TypoXLBold,
  TypoXSBold,
  TypoXXS,
  TypoXXSBold,
} from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import Link from '@components/Link';
import { useAppSelector } from '@hooks/useAppSelector';
import IconCoin from '@svg-icons/IconCoin';
import { getCoinValue } from '@utils/coin';
import routes, { route } from '@utils/routes';
import styles from './css';

const Hero = () => {
  const { t } = useTranslation('browse');
  const { heroImages, neighbor } = useAppSelector((state) => ({
    heroImages: state.heroImages,
    neighbor: state.neighbor,
  }));

  return (
    <section className="hero-images-wrapper">
      <h1 className="catch-copy">
        <TypoXLBold color="white" text={t('heroImages.title')} />
      </h1>
      <SwiperLibrary
        autoplay={{
          delay: 4000,
        }}
        effect="fade"
        loop={true}
        modules={[Navigation, Autoplay, Pagination, EffectFade]}
        navigation={{
          nextEl: '.carousel-button-next',
          prevEl: '.carousel-button-prev',
        }}
        pagination={{
          clickable: true,
        }}
        speed={1500}
      >
        {heroImages.map(
          (
            {
              // ts-ignore will be removed after Property swagger is updated.
              // @ts-ignore Fix me
              coin_each_rank: coinRangeRank,
              // @ts-ignore Fix me
              coin_range_each_rank: coinRangeEachRank,
              id,
              name,
              prefecture,
            },
            index
          ) => {
            const imageBaseProps = {
              alt: name,
              'data-swiper-slide-index': index,
              fill: true,
              style: { objectFit: 'cover' } as CSSProperties,
            };
            const imagePrefixPath = `/images/hero-images/${id}_`;

            return (
              <SwiperSlide className="carousel-slide" key={id}>
                <Desktop>
                  <Image src={`${imagePrefixPath}l.jpg`} {...imageBaseProps} />
                </Desktop>
                <Mobile>
                  <Image src={`${imagePrefixPath}s.jpg`} {...imageBaseProps} />
                </Mobile>
                <div className="hero-images-information">
                  <div className="description">
                    <div className="description-cont">
                      <div>
                        <TypoXSBold color="white" text={name} />
                      </div>
                      <div className="hero-images-subTitle">
                        <TypoXXS color="white" text={prefecture} />
                      </div>
                      <div className="hero-images-subItem">
                        <div>
                          <div className="property-details-coin">
                            <IconCoin
                              color="white"
                              size="sm"
                              withFill={false}
                            />
                            <TypoSBold
                              color="white"
                              text={
                                `${getCoinValue(
                                  coinRangeEachRank?.[0] || coinRangeRank,
                                  neighbor.vip_rank || 'regular'
                                )}~` || ''
                              }
                            />
                          </div>
                        </div>
                        <Link
                          className="hero-images-link"
                          href={route(routes.property, { id })}
                        >
                          <TypoXXSBold
                            color="white"
                            text={`> ${t('heroImages.link')}`}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          }
        )}
      </SwiperLibrary>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default Hero;
