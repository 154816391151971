import { useIsGacha20250306CampaignActive } from '@hooks/useCampaign';
import CampaignBanner from '@routes/index/components/CampaignBanner';

const CampaignBannerContainer = () => {
  const isGacha20250306CampaignActive = useIsGacha20250306CampaignActive();

  if (!isGacha20250306CampaignActive) {
    return null;
  }

  return <CampaignBanner />;
};

export default CampaignBannerContainer;
