import useTranslation from 'next-translate/useTranslation';
import Button from '@atoms/Button';
import { TypoMBold, TypoSBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import routes from '@utils/routes';
import styles from './css';

const FloatCtaButton = () => {
  const { t } = useTranslation('header');

  return (
    <div className="float-cta">
      <div className="float-cta-content">
        <Mobile>
          <Button
            btnStyle="signup"
            label={<TypoSBold color="white">{t('register')}</TypoSBold>}
            link={routes.signup}
            size="medium"
            width="full"
          />
        </Mobile>
        <Desktop>
          <Button
            btnStyle="signup"
            label={<TypoMBold color="white">{t('register')}</TypoMBold>}
            link={routes.signup}
            size="x-medium"
            width="full"
          />
        </Desktop>
      </div>

      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default FloatCtaButton;
