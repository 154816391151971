import { useAppSelector } from '@hooks/useAppSelector';
import FloatCtaButton from '@routes/index/components/FloatCtaButton';
import { isEmpty } from '@utils/utils';

const FloatCtaButtonContainer = () => {
  const { neighbor } = useAppSelector((state) => ({
    neighbor: state.neighbor,
  }));

  if (!isEmpty(neighbor)) {
    return null;
  }

  return <FloatCtaButton />;
};

export default FloatCtaButtonContainer;
