import { FC } from 'react';
import { Translate } from 'next-translate';
import SectionHeader from '@atoms/SectionHeader';
import { Reservation } from '@services/hafh/types/generated';
import routes from '@utils/routes';
import { LANG_LOCALE } from '@utils/types';
import UpcomingPropertyReservation from '../UpcomingPropertyReservation';
import styles from './css';

type Props = {
  locale: LANG_LOCALE;
  reservations: Reservation[];
  t: Translate;
};

const UpcomingPropertyReservations: FC<Props> = ({
  locale,
  reservations,
  t,
}) => {
  return (
    <div className="top-reservation-wrapper">
      <SectionHeader
        heading={t('upcomingReservation.topReservationTitle')}
        link={{
          href: routes.reservations,
          text: t('upcomingReservation.topReservationLink'),
        }}
      />
      <ul className="top-reservation-list">
        {reservations.map((reservation) => (
          <li key={`${reservation.property.id}-${reservation.check_in_date}`}>
            <UpcomingPropertyReservation
              locale={locale}
              reservation={reservation}
            />
          </li>
        ))}
      </ul>

      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default UpcomingPropertyReservations;
