import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .float-cta {
    position: sticky;
    bottom: 16px;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      bottom: 24px;
    }
  }
  .float-cta-content {
    width: 200px;
    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      width: 343px;
    }
  }
`;

export default styles;
